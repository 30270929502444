<template>
  <div v-if="allowRead(document.documenttypeid.name)">
    <v-alert
      v-if="childDocumentItems.length === 0 && allowedChildren.length"
      icon="mdi-alert-circle-outline"
      prominent
      dense
      text
      type="info"
    >
      No items found
      <v-btn 
        v-for="allowedItem in allowedChildren" 
        :key="`add-btn-${allowedItem}`" 
        :to="allowedUrl(allowedItem)"
      >
        Add {{ allowedItem }}
      </v-btn>
    </v-alert>
    <DocumentItem
      v-if="documentItem && documentItem.documentitemid"
      :id="`dit_scroll_to_${documentItem.documentitemid}`"
      :item="documentItem"
      :render-children="false"
    />
    

    <div v-if="showDocumentItem && showChildren" :key="String(selectedLanguagesByDocument())">
      <DocumentItem 
        v-for="child in childDocumentItems"
        :id="`dit_scroll_to_${child.documentitemid}`"
        :key="`documentitempage-${child.documentitemid}`"
        :item="child"
      />
    </div>

    <!-- Navigation -->
    <v-navigation-drawer
      v-if="isMoreInfoPaneOpen"
      app
      floating
      right
      class="elevation-1"
      width="280"
    >
      <!-- Navigation menu info -->
      <template v-slot:prepend>
        <div class="d-flex pa-1 justify-end">
          <v-btn icon color="grey" @click="closePane">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-divider class="mb-1"></v-divider>
      </template>

      <Definition 
        v-if="definitionId"
        :key="definitionId"
        :explanation-id="Number(definitionId)"
        :definition-title="definitionTitle"
      />
      <Person 
        v-if="personId"
        :key="personId"
        :person-id="Number(personId)"
      />
      <Phrase 
        v-if="phraseId" 
        :key="phraseId"
        :phrase-id="Number(phraseId)"
      />
      <DocumentItemTranslation
        v-if="referenceLinkId"
        :key="referenceLinkId"
        :translation-id="referenceLinkId"
        :minimum="true"
        :show-url="true"
      />
    </v-navigation-drawer>

    <debug v-if="debug">
      <template slot="sub-heading">DocumentItemPage</template>
      <template slot="info">
        isLeafNode={{ String(isLeafNode) }}<br/>
        document={{ document }}<br/>
        documentItem={{ documentItem }}<br/>
        showChildren={{ showChildren }}
      </template>
    </debug>
  </div>
  <v-alert 
    v-else
    prominent
    type="error"  
  >
    Access is denied, you have the following groups: {{ groups }}
  </v-alert>
</template>
<script>
// document-item-manage
import { mapGetters, mapState } from 'vuex'
import configs from '@/configs'
import DocumentItem from '@/components/documentItems/DocumentItem'
import DocumentItemTranslation from '@/components/documentItemTranslations/DocumentItemTranslation'
import Debug from '@/components/common/Debug'
import Definition from '@/components/definitions/Definition'
import Person from '@/components/persons/Person'
import Phrase from '@/components/phrases/Phrase'

export default {
  name: 'DocumentItemPage',
  components: {
    DocumentItem,
    DocumentItemTranslation,
    Definition,
    Person,
    Phrase,
    Debug
  },
  data: () => ({
    connectionType: configs.connectionProperties.types,
    definitionId: null,
    definitionTitle: null,
    personId: null,
    phraseId: null,
    referenceLinkId: null
  }),
  computed: {
    ...mapGetters('documentItems', ['getDocumentItemsByParent', 'isMoreInfoPaneOpen']),
    ...mapGetters('documents', ['getDocumentDataStructure', 'renderComponent', 'selectedLanguagesByDocument', 'getDocumentItemDataStructureById']),
    ...mapGetters('languages', ['languageShortCodeById']),
    ...mapGetters('app', ['mainDrawer']),
    ...mapGetters('account', ['allowRead']),
    ...mapState('account', ['groups']),
    ...mapState('documentItems', ['documentItem', 'documentItems', 'parentDocumentItem', 'documentItemTranslations', 'childDocumentItems', 'isLeafNode']),
    ...mapState('documents', ['document', 'dataStructure', 'selectedLanguages']),
    ...mapState('app', ['debug']),
    showChildren() {
      // if children exist and allowed items under this.
      return ((this.childDocumentItems && this.childDocumentItems.length) && this.allowedChildren.length)
    },
    showNavigation() {
      return this.documentItem && this.documentItem.documentid && !this.isLeafNode
    },
    showDocumentItem() {
      return this.documentItem && this.documentItem.documentid && this.isLeafNode && this.allowedChildren.length
    },
    childDocumentItems() {      
      if (this.documentItem && this.documentItem.documentitemid) {
        return this.getDocumentItemsByParent(this.documentItem.documentitemid)
      }
      
      return []
    },
    allowedChildren()  {
      if (this.documentItem && Object.keys(this.documentItem).length) { 
        const docItemStructure = this.getDocumentItemDataStructureById(this.documentItem.documentitemtypeid)

        if (!docItemStructure) { return [] }
        if (this.isLeafNode) { return docItemStructure.allowedChildren }
      }

      return []
    }
  },
  mounted() {
    if (this.$route.query.documentitem_focus) {
      this.$vuetify.goTo(`#dit_scroll_to_${this.$route.query.documentitem_focus}`)
    }
    this.enableCustomTags()
  },
  methods: {
    goto(refName) {
      const element = this.$refs[refName]
      const top = element.offsetTop
      
      console.dir(top)
      window.scrollTo(0, top)
    },
    allowedUrl(documentItemType) {
      const urlParams = {
        name: 'documentitem_add',
        query: {
          documentitemtype: documentItemType
        }
      }
      
      if (this.documentItem) {
        urlParams.query.parentdocumentitemid = this.documentItem.documentitemid
      }

      return urlParams
    },
    isRenderComponent(componentName) {
      return this.renderComponent(this.documentItem.documentitemtypeid, componentName)
    },
    closePane() {
      this.$store.commit('documentItems/toggleMoreInfo')
    },
    togglePane() {
      if (!this.isMoreInfoPaneOpen) {
        this.$store.commit('documentItems/toggleMoreInfo')
      }
    },
    showPerson(e) {
      this.personId = e.target.id
      this.togglePane()
    },
    showPhrase(e) {
      this.phraseId = e.target.id
      this.togglePane()
    },
    showDefinition(e) {
      this.definitionId = e.target.id
      this.definitionTitle = e.target.textContent
      this.togglePane()
    },
    showReferenceLink(e) {
      this.referenceLinkId = e.target.id
      this.togglePane()
    },
    // move the below into the correct place as its being loaded muliple times.
    enableCustomTags() {
      document.addEventListener('click', (event) => {
        if (!event.target) {
          return
        }

        if (event.target.matches('person')) {
          this.showPerson(event)
        }

        if (event.target.matches('phrase')) {
          this.showPhrase(event)
        }

        if (event.target.matches('definition')) {
          this.showDefinition(event)
        }

        if (event.target.matches('referencelink')) {
          this.showReferenceLink(event)
        }

        return
      })

    }
  }

}

</script>